.ctn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border: var(--border);
  z-index: 30;
  padding: 3em 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}