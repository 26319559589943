* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: navy;
  --secondary-color: lightgrey;
  --border: 1px solid lightgrey;
  --border-radius: 10px;
  --shadow: 0px 0px 50px 1px lightblue;


  color: var(--primary-color);
  font-family: RobotoR;
}

body {
  min-width: 100%;
  min-height: 100vh;
}

.border {
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1em;
}

.app {
  min-height: 100vh;
  min-width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
  font-family: RobotoR;
  src: url('/src/assets/fonts/Roboto-Regular.ttf');
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(132, 132, 255);
}

