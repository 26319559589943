.ctn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  box-shadow: 0px 0px 10px 1px lightblue;
  width: fit-content;
  height: fit-content;
  margin: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 
    box-shadow 0.25s ease, 
    background-color 0.25s ease, 
    transform 0.25s ease;
}

.item:hover {
  transform: scale(1.05);
}

.hoverItem {
  margin: 0.25rem;
  font-size: 1rem; 
}