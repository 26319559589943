.ctn {
  position: absolute;
  top: 20%;
  padding: 2em;
  border: 1px solid var(--border-color);
  border-radius: 20px;
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ctn > * {
  margin: 1em 0em;
}

.input {
  text-decoration: none;
  padding: 1em;
}