.ctn {
  composes: border from global;
  width: 90%;
  height: 90vh;
  display: flex;
  padding: 0;
  position: relative;
}

.modalBg {
  z-index: 20;
  background-color: rgba(128, 128, 128, 0.7);
  width: 100%;
  height: 100%;
  position: absolute; 
  composes: border from global;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.data {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 0;
  flex-grow: 1;
}

.data > * {
  padding: 0.5em;
  height: 100%;
}

.data > *:first-child {
  max-width: 70%;
  width: 70%;
}

.data > *:last-child {
  max-width: 30%;
  width: 30%;
}

.actions {
  padding-bottom: 1em;
  height: 4.5em;
  width: 100%;
  padding: 0.5em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn {
  background-color: lightgrey;
}

.deleteBtn {
  color: white;
  background-color: red;
  margin-right: 0.5em;
}

@media screen and (max-width: 1400px) {
  .ctn {
    height: 170vh;
    margin-top: 5em;
  }

  .data {
    flex-direction: column;
  }

  .data > *:first-child {
    height: 10%;
    width: 100%;
    max-width: 100%;
  }

  .data > *:last-child {
    height: 0;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
  }

  .actions {
    flex-wrap: wrap;
    justify-content: end;
  }

  .actions > * {
    margin: 0;
  }
}