.ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  width: 100%;
}

.cardCtn {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20em;
  height: 20em;
}

.cardCtn:hover {
  cursor: pointer;
}

.imgCtn {
  width: 100%;
  flex-basis: 60%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.cardFooter {
  flex-basis: 40%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.cardFooterText {
  margin: auto;
}

.cardFooterText > * {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 18em;
}

@media screen and (max-width: 1400px) {
  .ctn {
    margin-top: 5em;
  }
}