.ctn {
  composes: border from global;
  min-width: 0;
  width: 100%;
  font: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none; 
}

input[type=checkbox] {
  width: 2.25em;
  height: 2.25em;
}
