.vars {
  --display-height: 100%;
  --item-width: 12.5em;

}

.display {
  composes: vars;
  height: 100%;
  width: var(--body-width);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

.display:hover > .btnCtn {
  opacity: 1;
  pointer-events: all;
}

.track {
  height: 100%;
  width: fit-content;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  transition: transform 0.5s;
}

.item:not(:first-child) {
  margin-left: 0.25em;
}

.item {
  composes: vars;
  min-width: var(--item-width);
  width: var(--item-width);
  height: var(--display-height);
  background-color: black;
  overflow: hidden;
  border: var(--border);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.item .focused {
  border-width: 5px;
}

.item>img {
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
}

.btn {
  --btn-width: 2rem;
  --btn-height: 5rem;
  width: var(--btn-width);
  height: var(--btn-height);
  cursor: pointer;
  position: absolute;
  background-color: black;
  border-radius: 5px;
  color: white;
  z-index: var(--carousel-btn-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - var(--btn-height) / 2);
  transition: opacity 0.5s ease;
  z-index: 50;
  font-size: 2rem;
}

.btnCtn {
  transition: opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;
  z-index: 50;

}

.btn:hover {
  opacity: 1;
}

.leftBtn {
  composes: btn;
  left: 1rem;
}

.rightBtn {
  composes: btn;
  right: 1rem;
}