.ctn {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

}

.bg {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  filter: blur(50px);
  z-index: 0;
  position: absolute;
}

.img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  background-position: center;
  user-select: none;
  z-index: 1;
  -webkit-user-select: none; /* Chrome/Safari */ 
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.soldTag {
  /* background-color: red; */
  background-image: linear-gradient(to right, red 50%, transparent);
  opacity: 0.8;
  color: white;
  position: absolute;
  bottom: 0;
  padding: 1%;
  width: 100%;

  font-weight: 900;
  font-size: 1.5rem;
  /* transform: rotate(45deg); */
  text-align: start;
  z-index: 2;
}

.thumbnail {
  z-index: 2;
  width: 100%;
}

.reel {
  z-index: 2;
  height: 100%;
}



