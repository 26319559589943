.ctn {
  width: 100%;
  height: fit-content;
  position: fixed;
  top: 0;
  background-color: transparent;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--primary-color);
  font-size: 1.2rem;

  transition: background-color, box-shadow;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  z-index: 40;
}

.ctn > * {
  margin: 0.25em;
}




