.imageData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.imageData > * {
  width: 100%;
  margin: 0.25em;
}

.focusedImageCtn {
  composes: border from global;
  height: 0;
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.focusedImageCtn:hover .focusedOnHover {
  opacity: 0.85;
  pointer-events: all;
}

.focusedOnHover { 
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 5px;
  color: white;
  user-select: none;
  font-size: 1.5rem;
  padding: 0.5em;
  z-index: 50;
  -webkit-user-select: none; /* Chrome/Safari */ 
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.orderBtnCtn {
  composes: focusedOnHover;
  margin: 0 auto;
  bottom: 0;
}

.deleteImgCtn {
  composes: focusedOnHover;
  top: 0;
  right: 0;
}

.deleteImgBtn {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 0.15em;
}

.orderBtn {
  color: white;
  background-color: black;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orderBtn:active, .deleteImgBtn:active {
  color: grey;
}

.imageReel {
  composes: border from global;
  padding: 0;
  height: 20%;
  overflow: hidden;
}

.imageIdx { 
  position: absolute;
  background-color: black;
  color: white;
  padding: 0.25em;
  top: 0;
  left: 0;
}

.btn {
  background-color: var(--secondary-color);
}

.focusedReelItem {
  border-color: black;
  border-width: 5px;
  border-radius: 5px;
  /* border-radius: 10px; */
}