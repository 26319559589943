.ctn {
  color: var(--primary-color);
  background-color: white;
  /* background-image: linear-gradient(to right, var(--tertiary-color), blue); */
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color, background-color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  font-size: 1.1rem;
  white-space: nowrap;
}

:not(.pressed).ctn:hover {
  box-shadow: 0 0 10px 2px lightblue;
}

.pressed {
  background-color: navy;
  color: white;
  pointer-events: none;
}

.icon {
  margin-left: 0.5em;
}