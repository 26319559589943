.listingData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
}

.listingDataRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.listingDataRow:not(:first-child) {
  margin-top: 0.5em;
}

.listingDatum:not(:first-child) {
  margin-left: 0.5em;
}

.listingDatum {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 33%;
}